import React, { Component } from 'react';
import { Card, Row, Col } from 'antd';
import { bytesToBand } from "../Util";
import ReactEchartsCore from 'echarts-for-react/lib/core';
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/chart/line';
import 'echarts/lib/component/legend';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/title';
import {graphic} from "echarts/lib/export";

function getCpuOption() {
  return {
    title: {
      text: 'CPU Usage'
    },
    tooltip: {
      trigger: 'axis'
    },
    grid: {
      left: '2%',
      right: '4%',
      bottom: '2%',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        boundaryGap: false,
        data: []
      }
    ],
    yAxis: [
      {
        type: 'value',
        max: 100,
      }
    ],
    series: [
      {
        name: 'CPU Usage',
        type: 'line',
        areaStyle: {
          color: new graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: '#FEC8B1'
            },
            {
              offset: 1,
              color: '#FFF4F0'
            }])
        },
        color: '#FD9669',
        smooth: true,
        data: []
      },
    ]
  };
}

function getMemoryOption() {
  return {
    title: {
      text: 'Memory Usage'
    },
    tooltip: {
      trigger: 'axis'
    },
    grid: {
      left: '2%',
      right: '4%',
      bottom: '2%',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        boundaryGap: false,
        data: []
      }
    ],
    yAxis: [
      {
        type: 'value',
        max: 100,
      }
    ],
    series: [
      {
        name: 'Memory Usage',
        type: 'line',
        areaStyle: {
          color: new graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: '#F8C7C7'
            },
            {
              offset: 1,
              color: '#FFF4F0'
            }])
        },
        color: '#EC6161',
        smooth: true,
        data: []
      },
    ]
  };
}

function getConnOption() {
  return {
    title: {
      text: "Connections"
    },
    tooltip: {
      trigger: 'axis'
    },
    icon: 'circle',
    legend: {
      x: 'right',
      data: [
          { name: 'Rtmp' },
          { name: 'Http' },
          { name: 'WebSocket' },
      ]
    },
    grid: {
      left: '2%',
      right: '4%',
      bottom: '2%',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        boundaryGap: false,
        data: []
      }
    ],
    yAxis: [
      {
        type: 'value',
      }
    ],
    series: [
      {
        name: "Rtmp",
        type: 'line',
        color: '#E84C88',
        smooth: true,
        data: [],
      },
      {
        name: "Http",
        type: 'line',
        color: '#73C8FF',
        smooth: true,
        data: [],
      },
      {
        name: "WebSocket",
        type: 'line',
        color: '#8353DF',
        smooth: true,
        data: [],
      },
    ]
  };
}

function getNetOption() {
  return {
    title: {
      text: 'Network Bandwidth',
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        animation: false
      }
    },
    axisPointer: {
      link: { xAxisIndex: 'all' }
    },
    icon: 'circle',
    legend: {
      x: 'right',
      data: ['Input', 'Output']
    },
    grid: [{
      left: 50,
      right: 50,
      height: '35%'
    }, {
      left: 50,
      right: 50,
      top: '55%',
      height: '35%'
    }],
    xAxis: [
      {
        type: 'category',
        boundaryGap: false,
        axisLine: { onZero: true },
        data: [],
        show: false,
      },
      {
        gridIndex: 1,
        type: 'category',
        boundaryGap: false,
        axisLine: { onZero: true },
        data: [],
        position: 'bottom'
      }
    ],
    yAxis: [
      {
        name: 'Mbps',
        type: 'value',
      },
      {
        gridIndex: 1,
        type: 'value',
        inverse: true
      }
    ],
    series: [
      {
        name: 'Input',
        type: 'line',
        areaStyle: {
          color: new graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: '#9AE9CE'
            },
            {
              offset: 1,
              color: '#E3F9F1'
            }])
        },
        color: '#01C783',
        smooth: true,
        data: []
      },
      {
        name: 'Output',
        type: 'line',
        xAxisIndex: 1,
        yAxisIndex: 1,
        areaStyle: {
          color: new graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: '#EAECFE'
            },
            {
              offset: 1,
              color: '#A8B1FC'
            }])
        },
        color: '#4559F8',
        smooth: true,
        data: []
      }
    ]
  };
}

class DashboardPage extends Component {
  count = 0;
  lastInBytes = 0;
  lastOtBytes = 0;

  state = {
    cpuOption: getCpuOption(),
    memOption: getMemoryOption(),
    conOption: getConnOption(),
    netOption: getNetOption(),
  };

  componentDidMount() {
    this.fetch();
    this.timer = setInterval(this.fetch, 2000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  fetch = () => {

    let outBytes = 0

    let url = window.location.protocol + '//' + window.location.hostname + ':8100';

    url = 'http://ss.morningcopi.com:8101';
    fetch(url + '/api/server', {
      // credentials: 'include'
    }).then(function (response) {
      return response.json();
    }).then((data) => {

      // Out Byte.
      outBytes = data.net.outbytes

      url = 'http://ss.morningcopi.com:8100';
      fetch(url + '/api/server', {
        // credentials: 'include'
      }).then(function (response) {
        return response.json();
      }).then((data) => {
        this.lastInBytes = this.lastInBytes || data.net.inbytes;
        this.lastOtBytes = this.lastOtBytes || outBytes;
        //this.lastOtBytes = this.lastOtBytes || data.net.outbytes;

        let now = new Date();
        let axisData = now.toLocaleTimeString().replace(/^\D*/, '');

        let cpuOption = { ...this.state.cpuOption };
        let memOption = { ...this.state.memOption };
        let conOption = { ...this.state.conOption };
        let netOption = { ...this.state.netOption };

        if (this.count++ > 30) {
          cpuOption.xAxis[0].data.shift();
          cpuOption.series[0].data.shift();

          memOption.xAxis[0].data.shift();
          memOption.series[0].data.shift();

          conOption.xAxis[0].data.shift();
          conOption.series[0].data.shift();
          conOption.series[1].data.shift();
          conOption.series[2].data.shift();

          netOption.xAxis[0].data.shift();
          netOption.series[0].data.shift();

          netOption.xAxis[1].data.shift();
          netOption.series[1].data.shift();

        }

        cpuOption.uptime = now;
        cpuOption.xAxis[0].data.push(axisData);
        cpuOption.series[0].data.push(data.cpu.load);

        memOption.uptime = now;
        memOption.xAxis[0].data.push(axisData);
        memOption.series[0].data.push((100 - 100 * data.mem.free / data.mem.totle).toFixed(2));

        conOption.uptime = now;
        conOption.title.text = "Connections " + (data.clients.rtmp + data.clients.http + data.clients.ws);
        conOption.xAxis[0].data.push(axisData);
        conOption.series[0].data.push(data.clients.rtmp);
        conOption.series[1].data.push(data.clients.http);
        conOption.series[2].data.push(data.clients.ws);

        netOption.uptime = now;
        netOption.xAxis[0].data.push(axisData);
        netOption.xAxis[1].data.push(axisData);
        netOption.series[0].data.push(bytesToBand((data.net.inbytes - this.lastInBytes) / 2));
        netOption.series[1].data.push(bytesToBand((outBytes - this.lastOtBytes) / 2));
        // netOption.series[1].data.push(bytesToBand((data.net.outbytes - this.lastOtBytes) / 2));
        this.lastInBytes = data.net.inbytes;
        this.lastOtBytes = outBytes;
        // this.lastOtBytes = data.net.outbytes;
        this.setState({ cpuOption, memOption, conOption, netOption });
      }).catch(e => {
        console.log(e)
      });


    }).catch(e => {
      console.log(e)
    });

  }

  render() {

    return (
        <>
          <Row style={{ margin: "0 -12px" }}>
            <Col span={12} style={{ padding: "0 12px" }}>
              <Card>
                <ReactEchartsCore
                    echarts={echarts}
                    ref='echarts_react'
                    option={this.state.conOption}
                    style={{ height: '348px', width: '100%' }}
                />
              </Card>

            </Col>
            <Col span={12} style={{ padding: "0 12px" }}>
              <Card>
                <ReactEchartsCore
                    echarts={echarts}
                    ref='echarts_react'
                    option={this.state.netOption}
                    style={{ height: '348px', width: '100%' }}
                />
              </Card>
            </Col>

            <Col span={12} style={{ padding: "0 12px", marginTop: "16px" }}>
              <Card>
                <ReactEchartsCore
                    echarts={echarts}
                    ref='echarts_react'
                    option={this.state.cpuOption}
                    style={{ height: '300px', width: '100%' }}
                />
              </Card>
            </Col>
            <Col span={12} style={{ padding: "0 12px", marginTop: "16px" }}>
              <Card>
                <ReactEchartsCore
                    echarts={echarts}
                    ref='echarts_react'
                    option={this.state.memOption}
                    style={{ height: '300px', width: '100%' }}
                />
              </Card>
            </Col>
          </Row>
        </>
    );
  }

}

export default DashboardPage;

import React, {Fragment, useEffect, useRef, useState} from 'react';
import {Card, Row, Col, Select, Button, Slider } from 'antd';
import FlvPlayer from "./FlvPlayer";
import { useOpenCv } from 'opencv-react'
import { toJpeg } from 'html-to-image';
import { format } from 'date-fns';
import axios from "axios";

import html2canvas from 'html2canvas'

function EditorPage () {

    const { loaded: openCvLoaded, cv}  = useOpenCv()

    const videoRef = useRef()
    const previewRef = useRef()

    const [channel, setChannel] = useState('01')

    // const [inputVideo, setInputVideo] = useState('http://ss.morningcopi.com:8100/stream/' + channel + '.flv')
    const [inputVideo, setInputVideo] = useState('')

    const [bright, setBright] = useState(0);
    const [zoom, setZoom] = useState(1.0);
    const [xRatio, setXRatio] = useState(0.0);
    const [yRatio, setYRatio] = useState(0.0);

    const [contrast, setContrast] = useState(0);

    const handleChange = (value) => {
        setChannel(value)
    };

    const onChangeBright = (value) => {
        setBright(value[0]);
    };

    const onChangeContrast = (value) => {
        setContrast(value[0]);
    };

    const onChangeZoom = (value) => {
        setZoom(value[0]);
    };

    const onChangeXRatio = (value) => {
        setXRatio(value[0]);
    };

    const onChangeYRatio = (value) => {
        setYRatio(value[0]);
    };

    const saturateCast = (num) => {
        if (num > 255) return 255
        if (num < 0) return 0
        return num
    }

    const setValueContrast = (num)  => {
        return saturateCast(num + ((num - 128) * contrast))
    }

    const initValue = () => {
        setBright(0)
        setContrast(0)
        setZoom(1.0)
        setYRatio(0);
        setXRatio(0);
    }

    const apply = () => {

        const param = {
            channel: channel,
            bright: bright,
            contrast: contrast,
            zoom: zoom,
            yRatio: yRatio,
            xRatio: xRatio,
        };

        const url = window.location.protocol + '//' + window.location.hostname + ':8100';
        axios
            .post(url + "/api/editor/save", param)
            .then((res) => {
                console.log(res)
            });

    }

    const cvRefresh = () => {

        if (!openCvLoaded) return
        if (inputVideo === '') return

        // console.log(format(new Date, 'hh:mm:ss:SSS'))

        if (videoRef.current === null) return;
        if (previewRef.current === null) return;

        html2canvas(videoRef.current).then(canvas => {

            let res = canvas.toDataURL('image/jpeg', 0.7)
            canvas = null

            let img = new Image();
            img.src = res
            res = '';

            img.onload = () => {

                // const blackColor = new cv.Scalar(0, 0, 0, 255);
                // const redColor = new cv.Scalar(255, 0, 0, 255);
                // let srcBack = new cv.Mat(300, 400, cv.CV_8UC3, blackColor);
                // cv.Mat src5(480, 640, CV_8UC1, cv::Scalar(128));
                // let dst = new cv.Mat();
                // dst = src.clone();

                let src = cv.imread(img);
                img.src = ''
                img = null

                // 줌 적용.
                if (zoom !== 1) {

                    const width = src.cols / zoom;
                    const height = src.rows / zoom;

                    // 좌우 값 설정.
                    let left = (xRatio + 1) / 2
                    left *= src.cols - width
                    if (left < 0) left = 0;

                    // 상하 값 설정.
                    let top = (yRatio + 1) / 2
                    top *= src.rows - height
                    if (top < 0) top = 0;

                    let rect = new cv.Rect(left, top, width, height);
                    src = src.roi(rect);
                }

                // 밝기 조절. 명암 조절.
                if (bright !== 0 || contrast !== 0)
                    for (let i = 0; i < src.rows; i++) {
                        for (let j = 0; j < src.cols; j++) {

                            const w = src.ucharPtr(i, j);

                            if (bright !== 0) {
                                w[0] = saturateCast(w[0] + bright);
                                w[1] = saturateCast(w[1] + bright);
                                w[2] = saturateCast(w[2] + bright);
                            }

                            if (contrast !== 0) {
                                w[0] = setValueContrast(w[0]);
                                w[1] = setValueContrast(w[1]);
                                w[2] = setValueContrast(w[2]);
                            }

                        }
                    }


                if (zoom !== 1) {
                    // 스케일 조정.
                    cv.resize(src, src,  new cv.Size(400, 300), 0, 0, cv.INTER_LINEAR);
                }

                if (previewRef.current === null) {
                    src.delete();
                    return;
                }

                cv.imshow(previewRef.current, src);
                src.delete();

                // console.log(format(new Date, 'hh:mm:ss:SSS'))

            };

        })

        /*
        아래 코드는 메모리 누수가 있어서 사용 안함.
        toJpeg(videoRef.current, {
            fetchRequestInit: {cache: 'no-cache'},
            cacheBust: false,
            quality: 0.7
        })
            .then((res) => {

                let img = new Image();
                img.src = res
                res = '';

            }).catch((err) => {
            console.log(err)
        })
        */

    }

    const timerId = useRef(null);

    useEffect(() => {
        timerId.current = setInterval(() => {
            cvRefresh()
        }, 50)
        return () => {
            clearInterval(timerId.current)
        }

    })

    // const [inputVideo, setInputVideo] = useState('http://ss.morningcopi.com:8100/stream/' + channel + '.flv')

    const getInfo = () => {

        setInputVideo('http://ss.morningcopi.com:8100/stream/' + channel + '.flv')

        const url = window.location.protocol + '//' + window.location.hostname + ':8100';
        axios
            .get(url + "/api/editor/load?channel=" + channel)
            .then((res) => {
                if (res.data.success === true) {
                    const data = res.data.data;
                    setBright(data.bright)
                    setContrast(data.contrast)
                    setZoom(data.zoom)
                    setYRatio(data.yRatio);
                    setXRatio(data.xRatio);
                } else {
                    initValue()
                }
            });

    }

    useEffect(getInfo, [channel]);

    return (
        <Card>
            <Select
                defaultValue="01"
                size="large"
                style={{ marginBottom: "16px", width: '100%', }}
                onChange={handleChange}
                options={[
                    {
                        value: '01',
                        label: '01',
                    },
                    {
                        value: '02',
                        label: '02',
                    },
                    {
                        value: '03',
                        label: '03',
                    },
                    {
                        value: '04',
                        label: '04',
                    },
                ]}
            />

            <Row style={{ margin: "0 -12px" }}>
                <Col style={{ width: '475px', padding: "0 12px" }}>
                    <Card
                        style={{height: '405px'}}
                        bordered={true}
                        title={
                            <Fragment>
                                <span style={{paddingLeft: "12px", fontSize: "16px"}}>원본 영상</span>
                            </Fragment>
                        }>
                        <div ref={videoRef} id={'video'} style={{width: '400px', height: '300px'}}>
                            {inputVideo !== '' && channel === '01' &&
                                <FlvPlayer url={'http://ss.morningcopi.com:8100/stream/' + channel + '.flv'} type={'flv'} />
                            }
                            {inputVideo !== '' && channel === '02' &&
                                <FlvPlayer url={'http://ss.morningcopi.com:8100/stream/' + channel + '.flv'} type={'flv'} />
                            }
                            {inputVideo !== '' && channel === '03' &&
                                <FlvPlayer url={'http://ss.morningcopi.com:8100/stream/' + channel + '.flv'} type={'flv'} />
                            }
                            {inputVideo !== '' && channel === '04' &&
                                <FlvPlayer url={'http://ss.morningcopi.com:8100/stream/' + channel + '.flv'} type={'flv'} />
                            }
                        </div>
                    </Card>
                </Col>
                <Col style={{width: '475px', padding: "0 12px"}}>
                <Card
                        style={{ height: '405px' }}
                        bordered={true}
                        title={
                            <Fragment>
                                <span style={{paddingLeft: "12px", fontSize: "16px"}}>편집 정보</span>
                            </Fragment>
                        }>
                        <Row>
                            <Col span={7} >
                                <h4>밝기 ({bright})</h4>
                            </Col>
                            <Col span={16} >
                                <Slider range
                                        min={-100}
                                        max={100}
                                        value={bright}
                                        defaultValue={0} onChange={onChangeBright} />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={7} >
                                <h4>명암 ({contrast})</h4>
                            </Col>
                            <Col span={16} >
                                <Slider range
                                        min={0}
                                        max={1}
                                        step={0.1}
                                        value={contrast}
                                        defaultValue={0} onChange={onChangeContrast} />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={7} >
                                <h4>확대 ({zoom})</h4>
                            </Col>
                            <Col span={16} >
                                <Slider range
                                        min={1.0}
                                        max={2.0}
                                        step={0.1}
                                        value={zoom}
                                        defaultValue={0} onChange={onChangeZoom} />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={7} >
                                <h4>상하 이동 ({yRatio})</h4>
                            </Col>
                            <Col span={16} >
                                <Slider range
                                        min={-1.0}
                                        max={1.0}
                                        step={0.1}
                                        value={yRatio}
                                        defaultValue={0} onChange={onChangeYRatio} />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={7} >
                                <h4>좌우 이동 ({xRatio})</h4>
                            </Col>
                            <Col span={16} >
                                <Slider range
                                        min={-1.0}
                                        max={1.0}
                                        step={0.1}
                                        value={xRatio}
                                        defaultValue={0} onChange={onChangeXRatio} />
                            </Col>
                        </Row>
                        <Row
                            style={{ paddingTop: '30px' }}
                            align='center'>
                            <Col>
                                <Button onClick={initValue}>초기화</Button>
                                <Button onClick={apply}>적용</Button>
                            </Col>
                        </Row>
                        <Row
                            style={{ paddingTop: '50px' }}
                            align='center'>
                            <Col>
                                <p>※서버에 적용 되는데 10초 정도 소요 됩니다.</p>
                            </Col>
                        </Row>
                    </Card>
                </Col>

                <Col style={{ width: '475px', padding: "0 12px" }}>
                    <Card
                        style={{ height: '405px' }}
                        bordered={true}
                        title={
                            <Fragment>
                                <span style={{paddingLeft: "12px", fontSize: "16px"}}>미리보기</span>
                            </Fragment>
                        }>
                        <canvas
                            ref={previewRef}
                            width={400}
                            height={300}
                            style={{backgroundColor: 'white'}}
                        />
                    </Card>
                </Col>

            </Row>
        </Card>
    )

}

export default EditorPage;

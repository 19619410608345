import axios from "axios";
import { LOGIN_USER, REGISTER_USER, AUTH_USER } from "./types";

export function loginUser(dataToSubmit) {
    const url = window.location.protocol + '//' + window.location.hostname + ':8100';
    const request = axios
        .post(url + "/api/users/login", dataToSubmit)
        .then((res) => res.data);
    return {
        type: LOGIN_USER,
        payload: request,
    };
}

export function registerUser(dataToSubmit) {
    const url = window.location.protocol + '//' + window.location.hostname + ':8100';
    const request = axios
        .post(url + "/api/users/register", dataToSubmit)
        .then((res) => res.data);
    return {
        type: REGISTER_USER,
        payload: request,
    };
}

export function auth() {
    const url = window.location.protocol + '//' + window.location.hostname + ':8100';
    const request = axios.get(
        url + "/api/users/auth")
        .then((res) => res.data);
    return {
        type: AUTH_USER,
        payload: request,
    };
}
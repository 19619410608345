
import React, { Component } from 'react';
import {Card, Table, Modal, Input, Button} from "antd";
import { LockOutlined } from '@ant-design/icons';
import { secondsToDhmsSimple } from "../Util";
import FlvPlayer from "./FlvPlayer";
import Cookies from 'universal-cookie';
import md5 from 'js-md5';

class StreamsPage extends Component {
  cookies = new Cookies();

  state = {
    streamsData: [],
    loading: false,
    visible: false,
    password: ''
  };

  columns = [
    {
      title: 'App',
      dataIndex: 'app',
      key: 'app',
      align: 'center',
    },
    {
      title: 'In',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      render: (name, record) => {
        return (
            <Button onClick={() => this.openVideoIn(record)}>{name}</Button>
        );
      }
    },
    {
      title: 'Out',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      render: (name, record) => {
        return (
            <Button onClick={() => this.openVideoOut(record)}>{name}</Button>
        );
      }
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
    },
    {
      title: 'IP',
      dataIndex: 'ip',
      key: 'ip',
      align: 'center',
    },
    {
      title: 'Audio',
      children: [{
        title: 'codec',
        dataIndex: 'ac',
        key: 'ac',
        align: 'center',
      }, {
        title: 'freq',
        dataIndex: 'freq',
        key: 'freq',
        align: 'center',
      }, {
        title: 'chan',
        dataIndex: 'chan',
        key: 'chan',
        align: 'center',
      },
      ]
    }, {
      title: 'Video',
      children: [{
        title: 'codec',
        dataIndex: 'vc',
        key: 'vc',
        align: 'center',
      }, {
        title: 'size',
        dataIndex: 'size',
        key: 'size',
        align: 'center',
      }, {
        title: 'fps',
        dataIndex: 'fps',
        key: 'fps',
        align: 'center',
      },]
    },
    {
      title: 'Time',
      dataIndex: 'time',
      key: 'time',
      align: 'center',
    }, {
      title: 'Clients',
      dataIndex: 'clients',
      key: 'clients',
      align: 'center',
    }];

  componentDidMount() {
    this.setState({
      password: this.cookies.get('pass')
    })
    this.fetch();
  }

  updatePass = (e) => {
    let password = e.target.value;
    this.setState({
      password
    });
    this.cookies.set('pass', password, { path: '/', maxAge: 31536000 })
  }

  openVideoIn = (record) => {
    let sign = '';
    if (this.state.password) {
      let hash = md5.create();
      let ext = Date.now() + 30000;
      hash.update(`/${record.app}/${record.name}-${ext}-${this.state.password}`);
      let key = hash.hex();
      sign = `?sign=${ext}-${key}`;
    }
    let url = window.location.protocol + '//' + window.location.hostname + ':8100';
    url = 'http://ss.morningcopi.com:8100';
    this.videoModal = Modal.info({
      icon: null,
      title: "Video Player",
      width: 640,
      height: 480,
      content: <FlvPlayer url={url + `/${record.app}/${record.name}.flv${sign}`} type="flv" />,
    });
  }

  openVideoOut = (record) => {
    let sign = '';
    if (this.state.password) {
      let hash = md5.create();
      let ext = Date.now() + 30000;
      hash.update(`/${record.app}/${record.name}-${ext}-${this.state.password}`);
      let key = hash.hex();
      sign = `?sign=${ext}-${key}`;
    }
    let url = window.location.protocol + '//' + window.location.hostname + ':8100';
    url = 'http://ss.morningcopi.com:8101';
    this.videoModal = Modal.info({
      icon: null,
      title: "Video Player",
      width: 640,
      height: 480,
      content: <FlvPlayer url={url + `/${record.app}/${record.name}.flv${sign}`} type="flv" />,
    });
  }

  fetch = () => {
    this.setState({ loading: true });
    let url = window.location.protocol + '//' + window.location.hostname + ':8100';
    url = 'http://ss.morningcopi.com:8101';
    fetch(url + '/api/streams', {
      // credentials: 'include'
    }).then(function (response) {
      return response.json();
    }).then((data) => {
      // Read total count from server
      let streamsData = [];
      let index = 0;
      for (let app in data) {
        for (let name in data[app]) {
          let stream = data[app][name].publisher;
          let clients = data[app][name].subscribers;
          if (stream) {
            let now = new Date().getTime() / 1000;
            let str = new Date(stream.connectCreated).getTime() / 1000;
            let streamData = {
              key: index++,
              app,
              name,
              id: stream.clientId,
              ip: stream.ip,
              ac: stream.audio ? stream.audio.codec + " " + stream.audio.profile : "",
              freq: stream.audio ? stream.audio.samplerate : "",
              chan: stream.audio ? stream.audio.channels : "",
              vc: stream.video ? stream.video.codec + " " + stream.video.profile : "",
              size: stream.video ? stream.video.width + "x" + stream.video.height : "",
              fps: stream.video ? Math.floor(stream.video.fps) : "",
              time: secondsToDhmsSimple(now - str),
              clients: clients.length
            };
            streamsData.push(streamData);
          }
        }
      }
      this.setState({
        loading: false,
        streamsData,
      });
    }).catch(e => {
      this.setState({
        loading: false,
      });
    });
  }

  render() {
    return (
      <Card>
        <Input.Password
          size="large"
          prefix={<LockOutlined twoToneColor={'rgba(0, 0, 0, .25)'} />}
          style={{ marginBottom: "16px" }}
          placeholder="input password"
          onChange={this.updatePass}
          value={this.state.password} />
        <Table
          dataSource={this.state.streamsData}
          columns={this.columns}
          loading={this.state.loading}
          bordered
          size = {'middle'}
          pagination={false}
        />

      </Card>
    );
  }

}

export default StreamsPage;
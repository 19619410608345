import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { Layout, Menu, Row, Col } from 'antd';
import { HomeFilled, VideoCameraFilled, PlaySquareFilled, EditFilled, ProfileFilled, RightSquareFilled } from '@ant-design/icons';
import React, {useEffect, useState} from 'react';
import { OpenCvProvider } from 'opencv-react'
import DashboardPage from "./components/DashboardPage";
import ProfilePage from "./components/ProfilePage";
import StreamsPage from "./components/StreamsPage";
import RecordsPage from './components/RecordsPage'
import EditorPage from './components/EditorPage'
import LoginPage from "./components/LoginPage";

import "./App.css"

import imgLogo from './images/logo.jpg';

const { Sider, Content, Footer } = Layout;

function App() {

    const location= useLocation();

    let navigate = useNavigate();

    const [isAuthorized, setIsAuthorized] = useState(localStorage.getItem('userID') != null)

    const menuItems = [
        {
            key: '/',
            label: 'Dashboard',
            icon: <HomeFilled style={{ fontSize: "18px" }}/>,
        },
        {
            key: '/streams',
            label: 'Streams',
            icon: <VideoCameraFilled style={{ fontSize: "18px"}} />
        },
        {
            key: '/records',
            label: 'Records',
            icon: <PlaySquareFilled style={{ fontSize: "18px" }} />
        },
        {
            key: '/editor',
            label: 'Editor',
            icon: <EditFilled style={{ fontSize: "18px" }} />
        },
        {
            key: '/profile',
            label: 'Profile',
            icon: <ProfileFilled style={{ fontSize: "18px" }} />
        },
        {
            key: '/login',
            label: 'Logout',
            icon: <RightSquareFilled style={{ fontSize: "18px" }} />,
            disabled: !isAuthorized,
        },

    ]

    useEffect(() => {

        let login = localStorage.getItem('userID') != null;
        setIsAuthorized(login)

        if (!login) {
            if (location.pathname !== '/login') {
                navigate("/login");
            }
        }

    }, [ location.pathname ]);

    const onClick = (e) => {
        navigate(e.key);
    };

    const onLoaded = (cv) => {
        console.log('opencv loaded', cv)
    }

    return (
        <OpenCvProvider onLoad={onLoaded} openCvPath='/opencv/opencv.js'>
            <Layout style={{ minHeight: '100vh'}}>
                <Sider
                    style={{ backgroundColor: '#FFFFFF', boxShadow: '10px 0px 5px -2px #F6F3F2' }}
                    width={256}
                    trigger={null}
                    collapsible
                    collapsed={false}>
                    <div
                        style={{ alignItems: 'center', display: 'flex', justifyContent: 'center',  minHeight: '100px', backgroundColor: '#FFFFFF' }}
                        className="logo">
                        <img src={imgLogo} alt={'로고 없음'}/>
                    </div>
                    <Menu mode="inline"
                          onClick={onClick}
                          style={{ backgroundColor: '#FFFFFF' }}
                          defaultSelectedKeys={[location.pathname]}
                          items={menuItems}
                    />
                </Sider>
                <Layout style={{ background: '#FFFBF9' }}>
                    <Content style={{
                        margin: '30px 30px',
                        minHeight: 280,
                        background: '#FFFBF9'
                    }}>
                        <Routes>
                            <Route exact path="/" element={<DashboardPage/>} />
                            <Route path="/dashboard" element={<DashboardPage/>} />
                            <Route path="/streams" element={<StreamsPage/>} />
                            <Route path="/records" element={<RecordsPage/>} />
                            <Route path="/editor" element={<EditorPage/>} />
                            <Route path="/profile" element={<ProfilePage/>} />
                            <Route path="/login" element={<LoginPage/>} />
                            {/*
                            <Route path="/register" element={<RegisterPage/>} />
                            */}
                        </Routes>
                    </Content>
                    <Footer style={{ background: '#FFFBF9' }}>
                        <Row>
                            <Col span={12} style={{ padding: "0" }}>
                                <p style={{ padding: "0", fontSize: '11px'}}>©copyrights 2024 Simsysglobal Inc.</p>
                            </Col>
                            <Col span={12} style={{ padding: "0", textAlign: 'right' }}>
                                <p style={{ padding: "0", fontSize: '11px'}}>E-mail : info@simsysglobal.com TEL : 02-6953-9251</p>
                            </Col>
                        </Row>

                    </Footer>
                </Layout>
            </Layout>
        </OpenCvProvider>
    )

}

export default App;

// LoginPage.js

import React, {Fragment, useEffect, useState} from "react";
import { Card, Input, Button } from "antd";
import { LeftSquareFilled } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginUser } from "../actions/user_action";
import Auth from "../hoc/auth";

import "../App.css"

function LoginPage() {

    const dispatch = useDispatch();
    let navigate = useNavigate();

    const [userID, setUserID] = useState("");
    const [password, setPassword] = useState("");

    const handleChange = (e) => {
        const { value, type } = e.target
        if (type === "text") setUserID(value)
        else if (type === "password") setPassword(value)
    };

    useEffect(()=>{
        localStorage.removeItem('userID')
    },[])

    const handleSubmit = (e) => {
        e.preventDefault();

        const body = {
            userID: userID,
            password: password,
        };

        dispatch(loginUser(body))
            .then((res) => {
                if (res.payload.loginSuccess) {
                    localStorage.setItem('userID', res.payload.userID);
                    navigate("/");
                } else {
                    alert(res.payload.message.toString());
                }
            });

    };

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "60vh",
            }}
        >
            <Card
                style={{ minWidth: '300px'}}
                bordered = {true}
                title={
                    <Fragment>
                        <LeftSquareFilled />
                        <span style={{ paddingLeft: "12px", fontSize: "16px" }}>사용자 로그인</span>
                    </Fragment>
                }>
                <form
                    style={{display: "flex", flexDirection: "column"}}
                    onSubmit={handleSubmit}
                >
                    <Input type="text" size="large" value={userID} placeholder={'아이디'} onChange={handleChange}/>
                    <br/>
                    <Input.Password type="password" size="large" value={password} placeholder={'비밀번호'} onChange={handleChange}/>
                    <br/>
                    <Button size="large" type="primary" htmlType="submit">로그인</Button>
                </form>
            </Card>
        </div>
    );
}

export default Auth(LoginPage, false);
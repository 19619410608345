
import React, { useEffect, useState } from 'react';
import {Card, Table, Select, Modal, Button} from "antd";
import ReactPlayer from 'react-player';

function RecordsPage() {

    const [channel, setChannel] = useState('01')
    const [fileList, setFileList] = useState([])
    const [loading, setLoading] = useState(false)

    const columns = [
        {
            title: 'No.',
            dataIndex: 'no',
            key: 'no',
            align: 'center',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            align: 'center',
            render: (name, record) => {
                return <Button onClick={() => openVideo(record)}>{name}</Button>;
            }
        },
        {
            title: 'Size',
            dataIndex: 'size',
            key: 'size',
            align: 'center',
        }
        ]

    const openVideo = (record) => {
        let url = window.location.protocol + '//' + window.location.hostname + ':8100';
        url = 'http://ss.morningcopi.com'
        url += '/record/' + channel + '/' + record.name;
        console.log(url)
        const videoModal = Modal.info({
            icon: null,
            title: "Record Player",
            width: 640,
            height: 480,
            content:
                    <ReactPlayer
                        className='react-player fixed-bottom'
                        url= {url}
                        width='600px'
                        height='450px'
                        controls = {true}
                        playing={true}
                    />
        });
    }

    const getInfo = () => {

        setLoading(true)
        const url = window.location.protocol + '//' + window.location.hostname + ':8100';
        fetch(url + '/api/record/list?channel=' + channel, {
            //            credentials: 'include'
        }).then(function (response) {
            return response.json();
        }).then((data) => {
            setFileList(data.list)
            setLoading(false)
        }).catch(e => {
            setLoading(false)
        });

    }

    const handleChange = (value) => {
        setChannel(value)
    };

    useEffect(getInfo, [channel]);

    return (
        <Card>
            <Select
                defaultValue="01"
                size="large"
                style={{ marginBottom: "16px", width: '100%', }}
                onChange={handleChange}
                options={[
                    {
                        value: '01',
                        label: '01',
                    },
                    {
                        value: '02',
                        label: '02',
                    },
                    {
                        value: '03',
                        label: '03',
                    },
                    {
                        value: '04',
                        label: '04',
                    },
                ]}
            />
            <Table
                dataSource={fileList}
                columns={columns}
                loading={loading}
                bordered
                size = {'middle'}
                pagination={true}
            />

        </Card>
    );

}

export default RecordsPage;
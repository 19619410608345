import React, {Component, Fragment, useEffect, useState} from 'react';

import { Card, Table } from "antd";
import { ProfileFilled } from '@ant-design/icons';

import { bytesToSize, secondsToDhms } from "../Util";

import "../App.css"

const columns = [
    {
        title: 'name',
        dataIndex: 'name',
        key: 'name',
        width: 200
    },
    {
        title: 'value',
        dataIndex: 'value',
        key: 'value',
    }
];

function ProfilePage() {

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)

    const getInfo = () => {

        setLoading(true)

        const url = window.location.protocol + '//' + window.location.hostname + ':8100';
        fetch(url + '/api/server', {
        //            credentials: 'include'
        }).then(function (response) {
            return response.json();
        }).then((data) => {
            // Read total count from server
            let osInfo = { key: 0, name: 'OS', value: data.os.arch + "_" + data.os.platform + "_" + data.os.release };
            let cpuInfo = { key: 1, name: "CPU", value: data.cpu.num + " x " + data.cpu.model };
            let memInfo = { key: 2, name: "Memory", value: bytesToSize(data.mem.totle) };
            let nodeInfo = { key: 3, name: "Node.js", value: data.nodejs.version };
            let uptimeInfo = { key: 4, name: "Uptime", value: secondsToDhms(data.nodejs.uptime) };
            let versionInfo = { key: 5, name: "Version", value: data.version };

            setData([osInfo, cpuInfo, memInfo, nodeInfo, uptimeInfo, versionInfo])
            setLoading(false)

        }).catch(e => {
            setLoading(false)
        });
    }

    useEffect(getInfo, []);

    return (
        <Card
            bordered = {true}
            title={
            <Fragment>
                <ProfileFilled />
                <span style={{ paddingLeft: "12px", fontSize: "16px" }}>Server Info</span>
            </Fragment>}>
            <Table
                columns={columns}
                dataSource={data}
                loading={loading}
                pagination={false}
                size = {'middle'}
                showHeader={false}
            />
        </Card>
    );

}

export default ProfilePage;
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

// import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { applyMiddleware, createStore } from "redux";
import promiseMiddleware from "redux-promise"; // promise를 사용하기 위한 미들웨어

import { thunk } from "redux-thunk"; // 비동기를 사용하기 위한 미들웨어

import Reducer from "./_reducers/index.js";
import { HashRouter, BrowserRouter } from "react-router-dom";

// 원래 store는 객체밖에 못받기 때문에 promise와 function을 사용하기 위해 미들웨어를 사용한다.

const createStoreWithMiddleware = applyMiddleware(
    promiseMiddleware,
    thunk
)(createStore);


/*
ReactDOM.render(
    // <BrowserRouter>
    <Provider>
        <App />
    </Provider>

    // </BrowserRouter>
    , document.getElementById('root'));
*/

/*
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <Provider
            store={createStoreWithMiddleware(
                Reducer,
                window.__REDUX_DEVTOOLS_EXTENSION__ &&
                window.__REDUX_DEVTOOLS_EXTENSION__()
            )}
        >
            <App />
        </Provider>
    </React.StrictMode>
);
*/

ReactDOM.render(
    <HashRouter>
        <React.StrictMode>
            <Provider
                store={createStoreWithMiddleware(
                    Reducer,
                    window.__REDUX_DEVTOOLS_EXTENSION__ &&
                    window.__REDUX_DEVTOOLS_EXTENSION__()
                )}
            >
                    <App />
            </Provider>
        </React.StrictMode>
    </HashRouter>
    , document.getElementById('root'));


// reportWebVitals();
import React, { useEffect } from "react";
// import { useDispatch } from "react-redux";
// import { loginUser } from "../actions/user_action";
// import { useNavigate } from "react-router-dom";

export default function (SpecificComponent, option, adminRoute = null) {
    // null => 아무나 출입이 가능한 페이지
    // true => 로그인한 유저만 출입이 가능한 페이지
    // false => 로그인한 유저는 출입 불가능한 페이지

    function AuthenticationCheck(props) {

        // const dispatch = useDispatch();
        // const navigate = useNavigate();

        useEffect(() => {
/*
            dispatch(loginUser()).then((res) => {
                console.log(res);

                if (!res.payload.isAuth) {
                    // 로그인 하지 않은 상태
                    if (option) {
                        navigate("/login");
                    }
                } else {
                    // 로그인 한 상태
                    if (adminRoute && !res.payload.isAdmin) {
                        // 관리자가 아닌데 관리자 페이지로 들어가려고 할 때
                        navigate("/");
                    } else {
                        if (!option) navigate("/");
                    }
                }
            });
 */
        }, []);

        return <SpecificComponent />;
    }

    return AuthenticationCheck;
}